import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { createStructuredSelector } from 'reselect';
import { Box, Chip, Grid, Skeleton, Stack, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

import PdfDocument from 'resources/pdfDocument.svg';

import { sliderSettings } from '../constants';
import { getAllTemplates, getCarouselTemplates } from '../helpers';
import * as selectors from '../selectors';

import DefaultTemplatePreview from './DefaultTemplatePreview';

const Container = styled(Box)`
    display: flex;
    margin: 32px 0 0 0;

    .slick-slider {
        padding: 0 32px;
        width: 100%;

        .slick-next {
            right: 0;
        }

        .slick-prev {
            left: 0;
        }
    }
`;

const TemplateCarousel = ({
    branchSelected,
    canModify,
    carouselTemplatesData,
    handleClickCreate,
    isFetchingTemplates,
    selectedCountry,
    templateCategories,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const [items, setItems] = useState(6);
    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        if (['xs', 'sm'].includes(breakpoint)) setItems(2);
        if (['md'].includes(breakpoint)) setItems(3);
        setItems(6);
    }, [breakpoint]);

    const handleOnClickChip = (categoryId) => {
        if (categoryId === selectedCategory) {
            setSelectedCategory(null);
            return;
        }
        setSelectedCategory(categoryId);
    };

    const carouselTemplates = getCarouselTemplates(
        carouselTemplatesData,
        selectedCategory
    );

    const filteredTemplateCategories = templateCategories.filter((category) => {
        const allTemplatesData = getAllTemplates(carouselTemplatesData);
        return allTemplatesData.some((template) => {
            return template.category.id === category.id;
        });
    });

    if (!selectedCountry) return null;

    if (isFetchingTemplates)
        return (
            <Grid container mb={2}>
                {Array.from(new Array(items)).map(() => (
                    <Grid
                        item
                        xs={9}
                        md={6}
                        lg={3}
                        key={`skeleton-${Math.random()}`}
                    >
                        <Skeleton height={218} variant="rounded" />
                    </Grid>
                ))}
            </Grid>
        );

    if (isEmpty(getCarouselTemplates(carouselTemplatesData)))
        return (
            <Grid container mb={2}>
                <Grid item xs={18}>
                    <Typography fontWeight="bold" variant="body2">
                        {t('There are no elements to show')}
                    </Typography>
                </Grid>
            </Grid>
        );
    return (
        <>
            {filteredTemplateCategories.length > 0 && (
                <Stack direction="row" spacing={0} flexWrap="wrap" gap="8px">
                    {filteredTemplateCategories.map((category) => {
                        return (
                            <Chip
                                key={category.id}
                                label={category.name}
                                onClick={() => handleOnClickChip(category.id)}
                                variant={
                                    selectedCategory === category.id
                                        ? 'default'
                                        : 'outlined'
                                }
                            />
                        );
                    })}
                </Stack>
            )}

            {carouselTemplates.length > 0 && (
                <Container id="templates-carousel">
                    <Slider {...sliderSettings}>
                        {carouselTemplates.map((carouselTemplate) => {
                            return (
                                <DefaultTemplatePreview
                                    canModify={canModify}
                                    onClick={() =>
                                        canModify &&
                                        handleClickCreate(
                                            branchSelected,
                                            carouselTemplate,
                                            carouselTemplate.type
                                        )
                                    }
                                    imgSrc={
                                        carouselTemplate?.image_thumbnail
                                            ? carouselTemplate.image_thumbnail
                                            : PdfDocument
                                    }
                                    isLocked={carouselTemplate.locked === 1}
                                    isNew={carouselTemplate.is_new}
                                    isOustanding={carouselTemplate.is_featured}
                                    key={carouselTemplate.id}
                                    title={carouselTemplate.title}
                                />
                            );
                        })}
                    </Slider>
                </Container>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    carouselTemplatesData: selectors.getCarouselTemplatesData,
    isFetchingTemplates: selectors.getIsFetchingCarouselTemplates,
    templateCategories: selectors.getTemplateCategoriesData,
});

TemplateCarousel.propTypes = {
    branchSelected: PropTypes.string,
    canModify: PropTypes.bool,
    carouselTemplatesData: PropTypes.object,
    handleClickCreate: PropTypes.func,
    isFetchingTemplates: PropTypes.bool,
    selectedCountry: PropTypes.string,
    templateCategories: PropTypes.array,
};

export default connect(mapStateToProps, null)(TemplateCarousel);
