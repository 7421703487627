export const DEBOUNCE_TIME = 500;

export const NAME = 'project-consumptions-modal-rhf';
export const LINE_KWH = 0;
export const LINE_KW = 1;
export const SERVICE_NUMBER_REGEX = /^\d{12}$/;

export const PREDICTED_COLOR_SERIE_1 = [
    'rgb(0, 143, 251)',
    'rgb(254, 176, 25)',
    'rgb(17, 39, 139)',
    'rgb(17, 39, 139)',
];

export const PREDICTED_COLOR_SERIE_2 = [
    'rgb(242, 174, 71)',
    'rgb(255, 203, 103)',
    'rgb(212, 160, 0)',
    'rgb(212, 160, 0)',
];

export const DEFAULT_RECEIPT_ORIGIN = 'descarga';
export const DEFAULT_SERVICE_NUMBER = '210 987 654 321';
export const MANUAL_RECEIPT_ORIGIN = 'manual';
export const AUTO_PDF_RECEIPT_ORIGIN = 'automatic_pdf';
export const SEMIAUTO_PDF_RECEIPT_ORIGIN = 'semiautomatic_pdf';
export const SEMIAUTO_RECEIPT_ORIGIN = 'semiautomatic';
export const DEFAULT_SERVICE_NUMBER_MASK = '*** *** *** *** *** *** *** ***';
export const CERTIFIED_SERVICE_NUMBER_MASK = '999 999 999 999';
export const IMAGE_ALLOWED_EXTENSIONS = 'image/png';

export const CONSUMPTIONS_CAPTURE_MODE = {
    MANUAL: 0,
    SCRAPPER: 1,
    CSV: 2,
    LISA: 3,
    CUPS: 4,
    IMPORT_CSV: 5,
    XML: 6,
    ZAP: 7,
};

export const UPLOAD_MODES = {
    CSV: 2,
    CUPS: 3,
    DATADIS: 1,
    DEFAULT: 0,
    ZAP: 4,
};

export const DATE_FORMATS = {
    DEFAULT: 'dd/MM/yyyy',
    LABEL: 'MMM yy',
    LISA: 'yyyy-MM-dd',
    SCRAPPER: 'yyyyMMdd',
};

export const MULTIPLIER_BY_UNIT_PREFIX = {
    k: 1,
    M: 1000,
    G: 1000000,
    T: 1000000000,
};

export const FORM_SECTIONS = {
    CONSUMPTION_HISTORY: ['summary'],
    CONSUMPTION_PROFILE: ['consumption_profile'],
    CONTRACTED_DEMAND: ['contracted_demand'],
    FILE: ['file'],
    GENERAL_INFORMATION: [
        'holder',
        'political_division1',
        'political_division2',
        'rate',
        'rate_division',
        'rate_division_summer',
        'rate_region',
        'service_number',
        'subsidy_rate',
        'zip_code',
    ],
    TERMS_CONSUMPTION: ['terms_consumption'],
};

export const TABS_FORM_SECTIONS = {
    ENERGY: [
        'CONSUMPTION_PROFILE',
        'CONTRACTED_DEMAND',
        'CONSUMPTION_HISTORY',
        'TERMS_CONSUMPTION',
    ],
    GENERAL: ['GENERAL_INFORMATION'],
    IMPORT: ['FILE'],
};

export const DEFAULT_DISTRIBUTION = {
    saturday: {
        0: 0.000981469849246231,
        1: 0.000981469849246231,
        2: 0.000981469849246231,
        3: 0.000981469849246231,
        4: 0.000981469849246231,
        5: 0.000981469849246231,
        6: 0.000981469849246231,
        7: 0.0015773936949320594,
        8: 0.0015773936949320594,
        9: 0.0015773936949320594,
        10: 0.0015773936949320594,
        11: 0.0015773936949320594,
        12: 0.0015773936949320594,
        13: 0.0015773936949320594,
        14: 0.0015773936949320594,
        15: 0.0015773936949320594,
        16: 0.0015773936949320594,
        17: 0.0015773936949320594,
        18: 0.0015773936949320594,
        19: 0.0015773936949320594,
        20: 0.0015773936949320594,
        21: 0.0015773936949320594,
        22: 0.0015773936949320594,
        23: 0.0015773936949320594,
    },
    sunday: {
        0: 0.000981469849246231,
        1: 0.000981469849246231,
        2: 0.000981469849246231,
        3: 0.000981469849246231,
        4: 0.000981469849246231,
        5: 0.000981469849246231,
        6: 0.000981469849246231,
        7: 0.000981469849246231,
        8: 0.000981469849246231,
        9: 0.000981469849246231,
        10: 0.000981469849246231,
        11: 0.000981469849246231,
        12: 0.000981469849246231,
        13: 0.000981469849246231,
        14: 0.000981469849246231,
        15: 0.000981469849246231,
        16: 0.000981469849246231,
        17: 0.000981469849246231,
        18: 0.000981469849246231,
        19: 0.0015773936949320594,
        20: 0.0015773936949320594,
        21: 0.0015773936949320594,
        22: 0.0015773936949320594,
        23: 0.0015773936949320594,
    },
    weekday: {
        0: 0.000981469849246231,
        1: 0.000981469849246231,
        2: 0.000981469849246231,
        3: 0.000981469849246231,
        4: 0.000981469849246231,
        5: 0.000981469849246231,
        6: 0.0015773936949320594,
        7: 0.0015773936949320594,
        8: 0.0015773936949320594,
        9: 0.0015773936949320594,
        10: 0.0015773936949320594,
        11: 0.0015773936949320594,
        12: 0.0015773936949320594,
        13: 0.0015773936949320594,
        14: 0.0015773936949320594,
        15: 0.0015773936949320594,
        16: 0.0015773936949320594,
        17: 0.0015773936949320594,
        18: 0.0015773936949320594,
        19: 0.0015773936949320594,
        20: 0.0010768126346015793,
        21: 0.0010768126346015793,
        22: 0.0015773936949320594,
        23: 0.0015773936949320594,
    },
};
