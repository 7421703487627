import { CHART_COLORS } from 'common/constants';
import {
    addDate,
    differenceInMinutesDate,
    parseDate,
} from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';
import {
    getEconomicChartTooltipMessage,
    getTooltipDateRange,
} from 'common/utils/helpersChart';

const getEconomicChatSeries = (infoPriceConsumtion) => {
    let conceptsDictionary = infoPriceConsumtion.reduce(
        (acc, month, index, array) => {
            let tempConcepts = { ...acc };
            const monthConcepts = month.value || {};
            Object.keys(monthConcepts).forEach((key) => {
                if (!tempConcepts[key]) {
                    let tempArray = Array(array.length).fill(0);
                    tempArray[index] = monthConcepts[key];
                    tempConcepts = { ...tempConcepts, [key]: tempArray };
                }
                tempConcepts[key][index] = monthConcepts[key];
            });
            return tempConcepts;
        },
        {}
    );
    return Object.keys(conceptsDictionary).map((conceptsDictionaryKey) => {
        return {
            name: conceptsDictionaryKey,
            data: conceptsDictionary[conceptsDictionaryKey],
        };
    });
};

const getEnergyChartSeries = (dataKw, dataKwh) => {
    if (dataKw.length > 0) {
        return [
            { name: 'kWh', data: dataKwh },
            { name: 'kW', data: dataKw },
        ];
    }
    return [{ name: 'kWh', data: dataKwh }];
};

const getEnergyChatYaxis = (dataKw, countryCurrencyLocale) => {
    if (dataKw.length > 0) {
        return [
            {
                axisBorder: { show: true, color: '#0073d0' },
                decimalsInFloat: 0,
                labels: {
                    formatter: (val) =>
                        numberFormat(val, {
                            decimals: 0,
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                            unit: 'kWh',
                        }),
                },
                title: { text: 'kWh' },
            },
            {
                axisBorder: { show: true, color: '#f2ae47' },
                decimalsInFloat: 0,
                labels: {
                    formatter: (val) =>
                        numberFormat(val, {
                            decimals: 0,
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                            unit: 'kW',
                        }),
                },
                opposite: true,
                title: { text: 'kW' },
            },
        ];
    }
    return {
        decimalsInFloat: 0,
        labels: {
            formatter: (val) =>
                numberFormat(val, {
                    decimals: 0,
                    locale: countryCurrencyLocale,
                    style: 'decimal',
                    unit: 'kWh',
                }),
        },
    };
};

export const getEnergyChartSettings = (
    dataKw,
    dataKwh,
    categories,
    countryCurrencyLocale,
    monthskwh
) => {
    return {
        id: 'chartEnergy',
        options: {
            chart: { background: '#ffffff00', toolbar: { show: false } },
            colors: ['#0073d0', '#f2ae47'],
            dataLabels: { enabled: false },
            legend: { show: false },
            tooltip: {
                x: {
                    formatter: (_, { dataPointIndex }) =>
                        getTooltipDateRange({
                            index: dataPointIndex,
                            seriesEnergy: monthskwh,
                        }),
                },
                y: {
                    formatter: (val = 0) =>
                        numberFormat(val.toFixed(0), {
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                        }),
                },
            },
            xaxis: { categories },
            yaxis: getEnergyChatYaxis(dataKw, countryCurrencyLocale),
        },
        series: getEnergyChartSeries(dataKw, dataKwh),
    };
};

export const getEconomicChartSettings = (
    infoPriceConsumtion,
    categories,
    countryCurrencyIso,
    countryCurrencyLocale,
    countryCurrencySymbol,
    monthskwh
) => {
    return {
        id: 'chartEconomic',
        options: {
            chart: {
                background: '#ffffff00',
                stacked: true,
                toolbar: {
                    show: false,
                },
            },
            colors: CHART_COLORS,
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
                showForSingleSeries: true,
            },
            tooltip: {
                x: {
                    formatter: (value, { dataPointIndex, w }) =>
                        getEconomicChartTooltipMessage({
                            consumptionHistory: monthskwh,
                            countryCurrencyIso,
                            countryCurrencyLocale,
                            dataPointIndex,
                            value,
                            w,
                        }),
                },
                y: {
                    formatter: (val) =>
                        numberFormat(val, {
                            currency: countryCurrencyIso,
                            locale: countryCurrencyLocale,
                            style: 'currency',
                        }),
                },
            },
            xaxis: {
                categories,
            },
            yaxis: {
                decimalsInFloat: 0,
                labels: {
                    formatter: (val) =>
                        numberFormat(val, {
                            currency: countryCurrencyIso,
                            locale: countryCurrencyLocale,
                            style: 'currency',
                            symbol: countryCurrencySymbol,
                        }),
                },
            },
        },
        series: getEconomicChatSeries(infoPriceConsumtion),
    };
};

export const getShowUpdateButton = (finalDate, periodicityType) => {
    const dateToCompareByPeriodicity = addDate(
        parseDate(finalDate, 'dd/MM/yyyy'),
        { days: (periodicityType + 1) * 34 }
    );

    return (
        differenceInMinutesDate(
            new Date(),
            dateToCompareByPeriodicity,
            'minutes'
        ) > 0
    );
};

export const handleStatusSelected = (handleClickStatus) => (item) => {
    if (item.value !== '') {
        handleClickStatus(item);
    }
};
