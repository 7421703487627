import React, { useState } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, TabPanel } from 'sunwise-ui';

import SingleAutocomplete from 'common/components/SingleAutocomplete';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import {
    DistributionSection,
    TabsSettings,
    TiersTooltip,
} from '../HourlyRateComponents';

import ChargesTab from './ChargesTab';
import EnergyDistribution from './EnergyDistribution';

const HourlyRateTabs = ({
    control,
    defaultValues,
    disabled,
    divisions,
    fetchScheduleRateConfiguration,
    getValues,
    hourlyRateData,
    othersUnitsDataToSelect,
    rateName,
    setHourlyRateData,
    setValue,
    showMode,
}) => {
    const [selectedTab, setSelectedTab] = useState('charges');
    const { t } = useTranslation();

    const handleOnChangeTab = (_, newValue) => setSelectedTab(newValue);

    const handleChangeDivision = (value) =>
        setHourlyRateData((prev) => ({ ...prev, division: value }));

    const handleSetHourlyDistribution = ({ tiers, distribution }) =>
        setHourlyRateData((prev) => ({
            ...prev,
            hourly_distribution: distribution,
            tiers,
        }));

    return (
        <>
            <TabsSettings
                handleOnChangeTab={handleOnChangeTab}
                hasEnergyConcepts
                selectedTab={selectedTab}
            />

            <TabPanel
                key="tab-panel-charges"
                selectedTab={selectedTab}
                value="charges"
            >
                <ChargesTab
                    control={control}
                    disabled={disabled}
                    getValues={getValues}
                    othersUnitsDataToSelect={othersUnitsDataToSelect}
                    rateName={rateName}
                    setValue={setValue}
                    showMode={showMode}
                />
            </TabPanel>

            <TabPanel
                key="energy-distribution"
                selectedTab={selectedTab}
                value="energy-distribution"
            >
                <Grid alignItems="center" container p={1}>
                    <Grid item xs md={9} xl={6}>
                        <SingleAutocomplete
                            fullWidth
                            label={t('Division')}
                            options={divisions}
                            onChange={(data) => {
                                handleChangeDivision(data);
                                fetchScheduleRateConfiguration({
                                    ...defaultValues,
                                    rate_division: data?.value,
                                    handleSetHourlyDistribution,
                                });
                            }}
                            value={hourlyRateData?.division}
                        />
                    </Grid>
                    <Grid item xs={3} sm={2} md={1} pb={1}>
                        {hourlyRateData?.tiers?.length > 0 && (
                            <TiersTooltip rateTiers={hourlyRateData.tiers} />
                        )}
                    </Grid>
                </Grid>

                {!isEmpty(hourlyRateData.hourly_distribution) && (
                    <DistributionSection
                        conceptName="energy"
                        control={control}
                        distributionComponent={EnergyDistribution}
                        hourlyDistribution={hourlyRateData.hourly_distribution}
                        showMode
                    />
                )}
            </TabPanel>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    divisions: selectors.getDivisionsItemsToSelect,
});

const mapDispatchToProps = (dispatch) => ({
    fetchScheduleRateConfiguration: (config) =>
        dispatch(actions.fetchScheduleRateConfiguration(config)),
});

HourlyRateTabs.propTypes = {
    control: PropTypes.object,
    defaultValues: PropTypes.object,
    disabled: PropTypes.bool,
    divisions: PropTypes.array,
    fetchScheduleRateConfiguration: PropTypes.func,
    getValues: PropTypes.func,
    hourlyRateData: PropTypes.object,
    othersUnitsDataToSelect: PropTypes.array,
    rateName: PropTypes.string,
    setHourlyRateData: PropTypes.func,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(HourlyRateTabs);
