import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import { FILE_ALLOWED_EXTENSIONS } from 'common/constants';
import csvImporter from 'common/modules/csvImporter';

import * as supplierIntegrationsSelectors from '../../supplierIntegrations/selectors';
import * as actions from '../actions';
import { IMAGE_ALLOWED_EXTENSIONS, UPLOAD_MODES } from '../constants';
import * as selectors from '../selectors';

import CUPSSearch from './CUPSSearch';
import RHFPDFFileUpload from './RHFPDFFileUpload';
import ZapForm from './ZapForm';

const UploadTabs = ({
    companyIsFromSpain,
    configCsvImporter,
    control,
    disabled,
    fetchCupsInfo,
    fileUploaded,
    getValues,
    handleUploadFile,
    hasCupsData,
    isFetchingCupsInfo,
    isFromScrapper,
    isMexicanAccount,
    saveConsumptionsCsv,
    setUploadMode,
    setValue,
    sipsIntegration,
    uploadMode,
    zapIntegration,
}) => {
    const { t } = useTranslation();

    const canUseCups = Boolean(sipsIntegration?.id);
    const canUseZap = Boolean(zapIntegration?.id);

    let acceptFiles = `${FILE_ALLOWED_EXTENSIONS.CSV}`;
    let acceptFilesText = '.csv';

    if (uploadMode === UPLOAD_MODES.DEFAULT) {
        acceptFiles += `,${FILE_ALLOWED_EXTENSIONS.PDF},${IMAGE_ALLOWED_EXTENSIONS},${FILE_ALLOWED_EXTENSIONS.XML}`;
        acceptFilesText += ', .pdf, .xml, .png';
    }

    const uploadModeButtons = [
        { mode: UPLOAD_MODES.DEFAULT, label: t('AI Reader') },
    ];

    if (isMexicanAccount)
        uploadModeButtons.push({ mode: UPLOAD_MODES.ZAP, label: 'ZAP' });

    if (canUseCups)
        uploadModeButtons.push({ mode: UPLOAD_MODES.CUPS, label: 'CUPS' });

    if (companyIsFromSpain)
        uploadModeButtons.push({
            label: 'DATADIS',
            mode: UPLOAD_MODES.DATADIS,
        });

    const handleOnChangeFile = (file, origin) => {
        setValue('file_backup', file);
        handleUploadFile({
            file,
            getValues,
            uploadMode: origin,
            sendToLisa: true,
            setValue,
        });
    };

    const handleSendFileToLisa = () => {
        const file = getValues('file_backup');

        if (!file) return;
        handleUploadFile({
            file,
            getValues,
            sendToLisa: true,
            setValue,
        });
    };

    return (
        <>
            {!disabled && uploadModeButtons?.length > 1 && (
                <Box display="flex" justifyContent="center">
                    <ToggleButtonGroup
                        exclusive
                        fullWidth
                        onChange={(_, value) =>
                            value !== null && setUploadMode(value)
                        }
                        size="small"
                        sx={{ mb: 3, maxWidth: '400px' }}
                        value={uploadMode}
                    >
                        {uploadModeButtons.map(
                            (button) =>
                                (button.condition === undefined ||
                                    button.condition) && (
                                    <ToggleButton
                                        key={button.mode}
                                        value={button.mode}
                                    >
                                        {button.label}
                                    </ToggleButton>
                                )
                        )}
                    </ToggleButtonGroup>
                </Box>
            )}

            {!disabled &&
                [UPLOAD_MODES.DEFAULT, UPLOAD_MODES.DATADIS].includes(
                    uploadMode
                ) && (
                    <RHFPDFFileUpload
                        accept={acceptFiles}
                        control={control}
                        disabled={disabled}
                        getValues={getValues}
                        handleSendToLisa={handleSendFileToLisa}
                        isFromScrapper={isFromScrapper}
                        name="file"
                        onChange={handleOnChangeFile}
                        sendToLisaText={t('Read with AI')}
                        setValue={setValue}
                        success={fileUploaded}
                        text={`${t('File upload')} (${acceptFilesText})`}
                        textSuccess={t('Upload was successful')}
                        uploadMode={uploadMode}
                        uploadText={t('Change file')}
                    />
                )}

            {!disabled && uploadMode === UPLOAD_MODES.CUPS && (
                <CUPSSearch
                    disabled={disabled || isFetchingCupsInfo}
                    fetchCupsInfo={fetchCupsInfo}
                    getValues={getValues}
                    hasCupsData={hasCupsData}
                    setValue={setValue}
                />
            )}

            {!disabled && uploadMode === UPLOAD_MODES.ZAP && (
                <ZapForm canUseZap={canUseZap} />
            )}

            {!disabled && configCsvImporter?.isOpen && (
                <csvImporter.Container
                    buttonText={t('Upload CSV')}
                    columns={[
                        {
                            columnName: 'date',
                            displayLabel: t('Date'),
                            required: true,
                            shouldFormat: false,
                            type: csvImporter.COLUMN_TYPES.DATE,
                            matchAliases: ['fecha', 'timestamp'],
                        },
                        {
                            columnName: 'time',
                            displayLabel: t('Time'),
                            matchAliases: ['hora', 'time'],
                            shouldFormat: false,
                            type: csvImporter.COLUMN_TYPES.TIME,
                            visible: (_, columnsFormat) =>
                                columnsFormat?.date &&
                                !columnsFormat.date.includes('HH'),
                        },
                        {
                            columnName: 'consumption',
                            displayLabel: `${t('Consumption')} (kWh)`,
                            matchAliases: [
                                'consumo total',
                                'consumo',
                                'consumption',
                                'energy',
                                'kwh',
                            ],
                            required: true,
                            type: csvImporter.COLUMN_TYPES.NUMBER,
                            priority: 1,
                        },
                        {
                            columnName: 'demand',
                            displayLabel: `${t('Demand')} (kW)`,
                            matchAliases: ['demanda', 'demand', 'kw', 'power'],
                            type: csvImporter.COLUMN_TYPES.NUMBER,
                        },
                    ]}
                    initialFile={configCsvImporter?.file}
                    onImport={(result, data) => {
                        saveConsumptionsCsv({
                            data,
                            getValues,
                            result,
                            setValue,
                        });
                    }}
                    returnFormattedData
                    visible={false}
                />
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    configCsvImporter: selectors.getConfigCsvImportModal,
    hasCupsData: selectors.getHasCupsInfoData,
    isFetchingCupsInfo: selectors.getIsFetchingCupsInfo,
    sipsIntegration: supplierIntegrationsSelectors.getSipsIntegration,
    zapIntegration: supplierIntegrationsSelectors.getZapIntegration,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCupsInfo: (config) => dispatch(actions.fetchCupsInfo(config)),
    handleUploadFile: (config) => dispatch(actions.handleUploadFile(config)),
    saveConsumptionsCsv: (config) =>
        dispatch(actions.saveConsumptionsCsv(config)),
});

UploadTabs.propTypes = {
    companyIsFromSpain: PropTypes.bool,
    configCsvImporter: PropTypes.object,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    fetchCupsInfo: PropTypes.func,
    fileUploaded: PropTypes.bool,
    getValues: PropTypes.func,
    handleUploadFile: PropTypes.func,
    hasCupsData: PropTypes.bool,
    isFetchingCupsInfo: PropTypes.bool,
    isFromScrapper: PropTypes.bool,
    isMexicanAccount: PropTypes.bool,
    saveConsumptionsCsv: PropTypes.func,
    setUploadMode: PropTypes.func,
    setValue: PropTypes.func,
    sipsIntegration: PropTypes.object,
    uploadMode: PropTypes.number,
    zapIntegration: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadTabs);
