import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Card, DataGrid, Grid } from 'sunwise-ui';

import { PlaceholderCardContactsDashboard } from 'common/components/placeholder';
import SingleAutocomplete from 'common/components/SingleAutocomplete';
import TitleWithDetail from 'common/components/TitleWithDetail';
import { useBreakpoint } from 'common/hooks';

import * as actions from '../actions';
import * as selectors from '../selectors';

import ContactFormatter from './ContactFormatter';
import DateFormatter from './DateFormatter';
import ProjectFormatter from './ProjectFormatter';
import ProposalFormatter from './ProposalFormatter';
import RateFormatter from './RateFormatter';
import SalesmanFormatter from './SalesmanFormatter';

const Container = ({
    commercialOffers,
    commercialOffersByRate,
    fetchCommercialOffers,
    groupedRates,
    isLoading,
    visible = true,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const [currentValue, setCurrentValue] = useState(null);

    useEffect(() => {
        if (currentValue?.value) {
            commercialOffersByRate(currentValue.value);
        } else {
            fetchCommercialOffers();
        }
    }, [currentValue]);

    let columns = [
        {
            field: 'parent',
            headerName: t('Name'),
            renderCell: ProposalFormatter,
            width: 150,
        },
        {
            field: 'project',
            flex: 1,
            headerName: t('Project'),
            renderCell: ProjectFormatter,
        },
        {
            field: 'contact',
            flex: 1,
            headerName: t('Contact'),
            renderCell: ContactFormatter,
        },
        {
            field: 'created_by',
            flex: 1,
            headerName: t('User'),
            renderCell: SalesmanFormatter,
        },
        {
            field: 'rate',
            flex: 1,
            headerName: t('Rate'),
            renderCell: RateFormatter,
        },
        {
            field: 'created_at',
            flex: 1,
            headerName: t('Date'),
            renderCell: DateFormatter,
        },
    ];

    if (breakpoint === 'xs') {
        columns = [
            {
                field: 'parent',
                flex: 1,
                headerName: t('Name'),
                renderCell: ProposalFormatter,
            },
            {
                field: 'project',
                flex: 1,
                headerName: t('Project'),
                renderCell: ProjectFormatter,
            },
        ];
    }

    if (!visible) return null;

    return (
        <Card id="card-last-offers">
            <Card.Header id="card-header-last-offers">
                <Grid alignContent="center" alignItems="center" container>
                    <Grid item xs={18} lg>
                        <TitleWithDetail variant="body2">
                            {t('Latest proposal', { count: 2 })}
                        </TitleWithDetail>
                    </Grid>

                    <Grid item xs={18} lg={5}>
                        <SingleAutocomplete
                            fullWidth
                            isLoading={isLoading}
                            label={t('Rate')}
                            options={groupedRates}
                            onChange={setCurrentValue}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </Card.Header>

            <PlaceholderCardContactsDashboard ready={isLoading}>
                <Card.Body
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 600,
                    }}
                >
                    {commercialOffers.length > 0 ? (
                        <DataGrid
                            autoHeight
                            columns={columns}
                            disableColumnMenu
                            disableSelectionOnClick
                            getRowHeight={() => 'auto'}
                            pageSize={5}
                            rows={commercialOffers}
                            rowsPerPageOptions={[5]}
                            sx={{
                                '& .MuiDataGrid-virtualScroller': {
                                    overflow: 'hidden',
                                },
                                '& .MuiDataGrid-root': {
                                    height: '100%',
                                },
                            }}
                        />
                    ) : (
                        <div>
                            <small>
                                <p>{t('Does not yet have proposals')}</p>
                            </small>
                        </div>
                    )}
                    <Box mt="auto" py={1}>
                        <Button
                            color="secondary"
                            component={RouterLink}
                            fullWidth
                            to="/proposals"
                            variant="text"
                            sx={{ textTransform: 'none' }}
                        >
                            {t('See more proposals')}
                        </Button>
                    </Box>
                </Card.Body>
            </PlaceholderCardContactsDashboard>
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    commercialOffers: selectors.getDataFetchCommercialOffers,
    isLoading: selectors.getIsFetchingCommercialOffers,
});

const mapDispatchToProps = (dispatch) => ({
    commercialOffersByRate: (id) =>
        dispatch(actions.commercialOffersByRate(id)),
    fetchCommercialOffers: () => dispatch(actions.fetchCommercialOffers()),
});

Container.propTypes = {
    commercialOffers: PropTypes.array,
    commercialOffersByRate: PropTypes.func,
    fetchCommercialOffers: PropTypes.func,
    groupedRates: PropTypes.array,
    isLoading: PropTypes.bool,
    visible: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
