import React from 'react';

import { useTheme } from '@mui/material/styles';
import { Chart, registerables, Tooltip } from 'chart.js';
import PropTypes from 'prop-types';
import { Chart as ChartJs } from 'react-chartjs-2';
import { Box } from 'sunwise-ui';

const ChartJsContainer = ({
    chartRef,
    data,
    height = '250px',
    options,
    plugins,
    type = 'bar',
}) => {
    const theme = useTheme();

    Chart.register(...registerables);

    Tooltip.positioners.mouse = (_, evtPos) => evtPos;

    Chart.defaults.animation.duration = 500;
    Chart.defaults.color = theme.palette.text.primary;
    Chart.defaults.datasets.line.fill = 'start';
    Chart.defaults.datasets.line.pointRadius = 0;
    Chart.defaults.datasets.line.tension = 0.1;
    Chart.defaults.font.family = theme.typography.fontFamily;
    Chart.defaults.font.size = 11;
    Chart.defaults.maintainAspectRatio = false;
    Chart.defaults.plugins.filler.drawTime = 'beforeDraw';
    Chart.defaults.plugins.filler.propagate = true;
    Chart.defaults.plugins.legend.position = 'bottom';
    Chart.defaults.responsive = true;
    Chart.defaults.scale.beginAtZero = true;
    Chart.defaults.scale.ticks.count = 5;
    Chart.defaults.plugins.legend.labels = {
        ...Chart.defaults.plugins.legend.labels,
        borderRadius: 2,
        boxHeight: 12,
        boxWidth: 12,
        useBorderRadius: true,
    };

    Chart.defaults.plugins.legend.onHover = (event, legendItem, legend) => {
        const datasetIndex = legendItem.datasetIndex;
        legend.chart.data.datasets.forEach((dataset, index) => {
            if (!dataset.backgroundColor) return;
            if (
                dataset.backgroundColor?.startsWith('rgb') &&
                index !== datasetIndex
            ) {
                dataset.backgroundColor = dataset.backgroundColor.replace(
                    '0.8',
                    '0.1'
                );
            }

            if (
                dataset.backgroundColor?.startsWith('#') &&
                index !== datasetIndex
            ) {
                dataset.backgroundColor = dataset.backgroundColor + '4D';
            }
        });
        legend.chart.update();
    };

    Chart.defaults.plugins.legend.onLeave = (event, legendItem, legend) => {
        legend.chart.data.datasets.forEach((dataset) => {
            if (!dataset.backgroundColor) return;

            if (dataset.backgroundColor?.startsWith('rgb')) {
                dataset.backgroundColor = dataset.backgroundColor.replace(
                    '0.1',
                    '0.8'
                );
            }

            if (dataset.backgroundColor?.startsWith('#')) {
                if (dataset.backgroundColor.slice(-2) === '4D') {
                    dataset.backgroundColor = dataset.backgroundColor.slice(
                        0,
                        -2
                    );
                }
            }
        });
        legend.chart.update();
    };

    return (
        <Box sx={{ height, my: 2, position: 'relative', width: '100%' }}>
            <ChartJs
                data={data}
                options={{ color: theme.palette.text.primary, ...options }}
                type={type}
                plugins={plugins}
                ref={chartRef}
            />
        </Box>
    );
};

ChartJsContainer.propTypes = {
    chartRef: PropTypes.object,
    data: PropTypes.object,
    height: PropTypes.string,
    options: PropTypes.object,
    plugins: PropTypes.array,
    type: PropTypes.string,
};

export default ChartJsContainer;
