import React, { useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grow, Popper } from '@mui/material';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
    Button,
    ButtonGroup,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
} from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import { arraytoDictionary } from 'common/utils/helpers';

import CreateContact from './components/CreateContact';
import CreateProject from './components/CreateProject';
import CreateProposal from './components/CreateProposal';
import CreateTracking from './components/CreateTracking';
import { BUTTONS_ACTIONS } from './constants';

const getOptions = () => [
    {
        button: BUTTONS_ACTIONS.CREATE_PROPOSAL,
        component: ({ onExited, onSucccess }) => (
            <CreateProposal onExited={onExited} onSucccess={onSucccess} />
        ),
        label: i18next.t('Create proposal'),
    },
    {
        button: BUTTONS_ACTIONS.CREATE_PROJECT,
        component: ({ onExited, onSucccess }) => (
            <CreateProject onExited={onExited} onSucccess={onSucccess} />
        ),
        label: i18next.t('Create project'),
    },
    {
        button: BUTTONS_ACTIONS.CREATE_CONTACT,
        component: ({ onExited, onSucccess }) => (
            <CreateContact onExited={onExited} onSucccess={onSucccess} />
        ),
        label: i18next.t('Create contact'),
    },
    {
        button: BUTTONS_ACTIONS.CREATE_TRACKING,
        component: ({ onExited, onSucccess }) => (
            <CreateTracking onExited={onExited} onSucccess={onSucccess} />
        ),
        label: i18next.t('Create tracking'),
    },
];

const Container = ({
    defaultButton = BUTTONS_ACTIONS.CREATE_PROJECT,
    masterButtonMode = true,
    onSucccess = null,
    variantButton = 'outlined',
}) => {
    const breakpoint = useBreakpoint();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [RenderComponent, setRenderComponent] = useState(null);
    const location = useLocation();
    const [selectedButton, setSelectedButton] = useState(defaultButton);
    const options = getOptions();
    const dictionaryOptions = arraytoDictionary(options, 'button');

    useEffect(() => {
        if (masterButtonMode) {
            // Define routes and corresponding buttons
            const routeButtonMap = {
                '/proposals': BUTTONS_ACTIONS.CREATE_PROPOSAL,
                '/record': BUTTONS_ACTIONS.CREATE_PROJECT,
                '/contacts': BUTTONS_ACTIONS.CREATE_CONTACT,
                '/follow-ups': BUTTONS_ACTIONS.CREATE_TRACKING,
            };
            // Check if the URL contains the ?tab= parameter
            const searchParams = new URLSearchParams(location.search);
            if (searchParams.has('tab')) {
                const tabValue = searchParams.get('tab');
                if (tabValue === 'projects')
                    setSelectedButton(BUTTONS_ACTIONS.CREATE_PROPOSAL);
                else setSelectedButton(BUTTONS_ACTIONS.CREATE_PROJECT);
            } else {
                // Sets the selected button based on the current route
                const currentButton =
                    routeButtonMap[location.pathname] || defaultButton;
                setSelectedButton(currentButton);
            }
        }
    }, [location.pathname, location.search]);

    const handleMainClick = () =>
        handleMenuItemClick(dictionaryOptions[selectedButton]);

    const handleMenuItemClick = ({ component }) => {
        if (component)
            setRenderComponent(
                component({
                    onExited: () => setRenderComponent(null),
                    onSucccess,
                })
            );
        setOpen(false);
    };

    const handleToggleMenu = () => setOpen((prevOpen) => !prevOpen);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target))
            return;
        setOpen(false);
    };

    return (
        <>
            <ButtonGroup
                ref={anchorRef}
                size={masterButtonMode ? 'small' : 'normal'}
            >
                <Button onClick={handleMainClick} variant={variantButton}>
                    {['xs'].includes(breakpoint) ? (
                        <AddIcon />
                    ) : (
                        dictionaryOptions[selectedButton]?.label
                    )}
                </Button>
                {masterButtonMode && (
                    <Button onClick={handleToggleMenu} size="small">
                        <ArrowDropDownIcon />
                    </Button>
                )}
            </ButtonGroup>

            <Popper
                anchorEl={anchorRef.current}
                disablePortal
                open={open}
                role={undefined}
                sx={{ zIndex: 1 }}
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem>
                                    {options.map((option) => (
                                        <MenuItem
                                            key={option.label}
                                            onClick={() =>
                                                handleMenuItemClick(option)
                                            }
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            {RenderComponent}
        </>
    );
};

Container.propTypes = {
    defaultButton: PropTypes.string,
    masterButtonMode: PropTypes.bool,
    onSucccess: PropTypes.func,
    variantButton: PropTypes.string,
};

export default Container;
