import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { HOURS_IN_DAY } from 'common/constants';
import {
    COMPENSATION_SCHEMES,
    HIGH_TENSION_RATES,
    HOURLY_RATES,
    RATES_FC,
    TIER_TYPES,
} from 'common/constants/rates';
import {
    differenceInDaysDate,
    isInvalidLeapYearDay,
    parseDate,
} from 'common/utils/dates';

// Utils
export const cleanNumberValue = (value) =>
    value || value === 0 ? Number(value) || 0 : null;

export const getDayType = (day) => {
    switch (day) {
        case 0:
            return 'sunday';
        case 6:
            return 'saturday';
        default:
            return 'weekday';
    }
};

export const getDaysInPeriod = ({ final_date, initial_date }) =>
    !final_date || !initial_date
        ? 0
        : differenceInDaysDate(
              parseDate(final_date, 'dd/MM/yyyy'),
              parseDate(initial_date, 'dd/MM/yyyy')
          );

export const getEstimatedDemand = ({ diffDays, rateName, total } = {}) =>
    Math.ceil(
        (Number(total) || 0) /
            (HOURS_IN_DAY * diffDays * (RATES_FC[rateName] || 1))
    );

export const getHasLowTensionConcept = (rateName) =>
    HIGH_TENSION_RATES.includes(rateName);

export const getIsGDRate = (selectedRate) =>
    selectedRate?.isCertified
        ? HIGH_TENSION_RATES.includes(selectedRate.name)
        : String(selectedRate?.formType) === '1';

export const getIsHourlyRate = (selectedRate) => {
    if (!selectedRate) return false;

    const { isCertified, name, paymentType } = selectedRate;

    return (
        (isCertified && HOURLY_RATES.includes(name)) ||
        (!isCertified && paymentType === 'Schedule')
    );
};

export const formatTierField = ({ isCertified, oldField, tier }) => {
    if (!tier) return {};
    const { identifier, name = '' } = tier;

    return {
        id: oldField?.id,
        label: isCertified ? i18next.t(name, { context: 'rate' }) : name,
        placeholder: `${oldField?.placeholder || '0'}`,
        tier: identifier,
        value: oldField?.value ?? null,
    };
};

// Fields from tiers
export const getFieldsFromTiers = ({ concepts, isCertified, tiers }) => {
    const fields = {};
    let total = 0;

    if (!tiers?.length) return { fields, total };

    const conceptsByTier = concepts?.reduce((acc, curr) => {
        acc[curr.tier] = curr;
        return acc;
    }, {});

    for (const tier of tiers) {
        const tierName = tier?.name || '';
        const field = conceptsByTier?.[tier.identifier];
        const value = Number.parseInt(field?.consumption);
        fields[tierName.toLowerCase()] = formatTierField({
            isCertified,
            oldField: { id: field?.id, value: cleanNumberValue(value) },
            tier,
        });

        total += value || 0;
    }

    return { fields, total };
};

export const getEnergyAndDemandFields = ({
    isCertified,
    period,
    tiers_energy_distribution,
}) => {
    const fields = {};
    let total = 0;
    if (period?.energy) {
        const { fields: energyFields, total: totalEnergy } = getFieldsFromTiers(
            {
                concepts: period?.energy,
                isCertified,
                tiers: tiers_energy_distribution?.[0]?.tiers,
            }
        );
        fields.kWh = energyFields;
        total = totalEnergy;
    }
    if (period?.demand) {
        const { fields: demandFields } = getFieldsFromTiers({
            concepts: period?.demand,
            isCertified,
            tiers: tiers_energy_distribution?.[0]?.tiers,
        });
        fields.kW = demandFields;
    }

    return { fields, total };
};

// Fields by period
export const getDailyAvgFields = (total, final_date, initial_date) => {
    const days = getDaysInPeriod({ final_date, initial_date });
    const value = total && days ? (total / days).toFixed(2) : null;
    return { placeholder: value, value };
};

export const getTierValue = ({ day, hour, month, tiers_distribution }) => {
    if (!tiers_distribution) return 0;
    const index = month * HOURS_IN_DAY + hour;
    switch (day) {
        case 0:
            return tiers_distribution.sunday_distribution[index] || 0;

        case 6:
            return tiers_distribution.saturday_distribution[index] || 0;

        default:
            return tiers_distribution.weekday_distribution[index] || 0;
    }
};

const getFieldsByConcept = ({
    discardValues,
    distribution,
    finalDate,
    initialDate,
    period,
    rate,
    tiers_distribution,
    tmp_kW,
}) => {
    const kW = {};
    const kWh = {};
    const missingFields = { kWh: {}, kW: {} };
    let total = 0;
    let totalPlaceholder = 0;

    if (!tiers_distribution?.tiers?.length)
        return {
            kW,
            kWh,
            missingFields,
            total: {
                placeholder: '0',
                value: cleanNumberValue(period?.total?.value),
            },
        };

    const mappedTiers = tiers_distribution?.tiers?.reduce((acc, tier) => {
        acc[tier.identifier] = tier;
        return acc;
    }, {});

    const hoursDistribution = getHoursDistributionByTier({
        finalDate,
        initialDate,
        tiers_distribution,
    });

    const distributionRatio = getDistributionRatioByTier({
        distribution,
        hoursDistribution,
    });

    const isGDRate = getIsGDRate(rate);
    const _total = period?.total?.value;
    const hasTotal = _total || _total === 0;
    const shouldApplyDistribution =
        !discardValues && hasTotal && !isEmpty(distributionRatio);

    for (const tierKey in hoursDistribution) {
        const tierHours = hoursDistribution[tierKey]?.total || 0;
        if (tierHours <= 0) continue;

        const tier = mappedTiers[tierKey];
        const tierNameLower = tier?.name?.toLowerCase() || '';

        const energyValue = shouldApplyDistribution
            ? Math.round(distributionRatio[tierKey] * _total)
            : cleanNumberValue(period?.kWh?.[tierNameLower]?.value);

        kWh[tierNameLower] = formatTierField({
            isCertified: rate.isCertified,
            oldField: { placeholder: energyValue, value: energyValue },
            tier,
        });

        if (tier.tier_type === TIER_TYPES.ENERGY) {
            total += energyValue || 0;
            totalPlaceholder += energyValue || 0;
        }

        if (!isGDRate) continue;
        const demandValues = period?.kW?.[tierNameLower];
        kW[tierNameLower] = formatTierField({
            isCertified: rate.isCertified,
            oldField: {
                placeholder: demandValues?.placeholder,
                value: cleanNumberValue(demandValues?.value) || tmp_kW,
            },
            tier,
        });
    }

    if (discardValues) {
        for (const key in period?.kWh) {
            const periodField = period?.kWh?.[key];
            if (!kWh[key] && Number(periodField?.value))
                missingFields.kWh[periodField.label] = periodField.value;
        }

        for (const key in period?.kW) {
            const periodField = period?.kW?.[key];
            if (!kW[key] && Number(periodField?.value))
                missingFields.kW[periodField.label] = periodField.value;
        }
    }

    return {
        hoursDistribution,
        kW,
        kWh,
        missingFields,
        total: {
            placeholder: `${totalPlaceholder || 0}`,
            value: !hasTotal && !total ? null : total,
        },
    };
};

export const getFieldsInPeriod = ({
    discardValues,
    distribution,
    finalDate,
    initialDate,
    period,
    rate,
    tiers_energy_distribution,
    tmp_kW,
}) => {
    const fields = { kWh: {}, kW: {} };

    const isHourlyRate = getIsHourlyRate(rate);

    if (!tiers_energy_distribution || !isHourlyRate)
        fields.total = {
            placeholder: `${period?.total?.placeholder || 0}`,
            value: cleanNumberValue(period?.total?.value),
        };

    const hourlyFields = getFieldsByConcept({
        discardValues,
        distribution,
        finalDate,
        initialDate,
        period,
        rate,
        tiers_distribution: isHourlyRate
            ? tiers_energy_distribution?.[0]
            : null,
        tmp_kW,
    });

    if (getIsGDRate(rate) && isEmpty(hourlyFields?.kW))
        fields.kW.peak = {
            label: 'kW',
            placeholder:
                period?.kW?.peak?.placeholder ||
                period?.kW?.unique?.placeholder ||
                `${tmp_kW || 0}`,
            value:
                period?.kW?.peak?.value ||
                period?.kW?.unique?.placeholder ||
                tmp_kW ||
                null,
        };

    const total = hourlyFields.total;

    return {
        dailyAvg: getDailyAvgFields(total?.value, finalDate, initialDate),
        hasMissingFields:
            !isEmpty(hourlyFields.missingFields?.kWh) ||
            !isEmpty(hourlyFields.missingFields?.kW),
        hoursDistribution: hourlyFields.hoursDistribution || {},
        kW: { ...hourlyFields.kW, ...fields.kW },
        kWh: hourlyFields.kWh,
        missingFields: hourlyFields.missingFields,
        total,
    };
};

// Fields for API
export const getConsumptionValue = (field = {}) => {
    if (!field) return 0;
    const hasUserValue = field?.value || field?.value === 0;
    return Number.parseInt(hasUserValue ? field.value : field.placeholder) || 0;
};

export const normalizeHourlyFields = (fields = {}) => {
    return Object.values(fields).map((field) => ({
        consumption: getConsumptionValue(field),
        id: field.id,
        tier: field.tier,
    }));
};

export const getNormalizedSummary = ({
    contracted_demand = {},
    summary = [],
}) => {
    const contractedDemandValues = normalizeHourlyFields(contracted_demand);

    return summary.map((period) => {
        const values = {};
        if (!isEmpty(period?.kWh))
            values.energy = normalizeHourlyFields(period.kWh);

        if (!isEmpty(period?.kW))
            values.demand = normalizeHourlyFields(period.kW);

        if (!isEmpty(contractedDemandValues))
            values.contracted_demand = contractedDemandValues;

        return {
            ...values,
            final_date: period.final_date,
            id: period.id,
            initial_date: period.initial_date,
        };
    });
};

// Distribution
export const getHoursDistributionByTier = ({
    finalDate,
    initialDate,
    tiers_distribution,
}) => {
    if (isEmpty(tiers_distribution) || !finalDate || !initialDate) return {};
    const hoursDistribution = {};
    const [initialDay, initialMonth, initialYear] = initialDate.split('/');
    const [finalDay, finalMonth, finalYear] = finalDate.split('/');
    const finalDateUTC = new Date(
        Date.UTC(finalYear, finalMonth - 1, Number(finalDay) + 1)
    );
    const date = new Date(
        Date.UTC(initialYear, initialMonth - 1, Number(initialDay) + 1)
    );

    while (date < finalDateUTC) {
        const day = date.getUTCDate();
        const hour = date.getUTCHours();
        const month = date.getUTCMonth();
        const year = date.getUTCFullYear();

        date.setUTCHours(hour + 1);

        if (isInvalidLeapYearDay({ day, month, year })) continue;

        const dayType = date.getUTCDay();
        const dayTypeText = getDayType(dayType);

        const tierValue = getTierValue({
            day: dayType,
            hour,
            month,
            tiers_distribution,
        });

        if (!hoursDistribution[tierValue])
            hoursDistribution[tierValue] = { total: 0 };
        if (!hoursDistribution[tierValue][dayTypeText])
            hoursDistribution[tierValue][dayTypeText] = {};

        if (!hoursDistribution[tierValue][dayTypeText][hour])
            hoursDistribution[tierValue][dayTypeText][hour] = 0;

        hoursDistribution[tierValue][dayTypeText][hour]++;
        hoursDistribution[tierValue].total++;
    }

    return hoursDistribution;
};

export const getDistributionRatioByTier = ({
    distribution,
    hoursDistribution,
}) => {
    if (isEmpty(distribution) || isEmpty(hoursDistribution)) return {};

    const ratio = {};
    let total = 0;

    for (const tier in hoursDistribution) {
        let tierRatio = 0;
        const tierDistribution = hoursDistribution[tier];

        for (const day in tierDistribution) {
            if (day === 'total') continue;

            const tierDayDistribution = tierDistribution[day];
            const dayDistribution = distribution[day] || {};

            for (const hour in tierDayDistribution) {
                const relation =
                    (dayDistribution[hour] || 0) * tierDayDistribution[hour];

                tierRatio += relation;
                total += relation;
            }
        }

        ratio[tier] = tierRatio;
    }

    for (const tier in ratio) ratio[tier] /= total || 0;
    return ratio;
};

export const calcEnergyDistributionPercentages = (summary) => {
    const total = summary.reduce(
        (acc, curr) => (curr?.total?.value || 0) + acc,
        0
    );

    if (!summary?.length || !total) return {};
    const distribution = {};

    for (const period of summary) {
        if (!period?.total?.value) continue;
        const { hoursDistribution = {}, kWh = {} } = period;

        for (const field in kWh) {
            const kWhField = kWh[field];
            if (!kWhField?.value && kWhField?.value !== 0) continue;

            const tier = kWhField.tier;
            const tierHoursDistribution = hoursDistribution?.[tier];

            if (!tierHoursDistribution) continue;
            const hourlyValue =
                kWhField.value / (total * tierHoursDistribution.total);

            for (const day in tierHoursDistribution) {
                if (day === 'total') continue;
                if (!distribution[day]) distribution[day] = {};

                const tierHoursDistributionDay = tierHoursDistribution[day];
                for (const hour in tierHoursDistributionDay) {
                    if (!distribution[day][hour]) distribution[day][hour] = 0;
                    distribution[day][hour] += hourlyValue || 0;
                }
            }
        }
    }

    return distribution;
};

// Compensation schemes
export const getCompensationHasNettedExportedGeneration = (value) =>
    value === 'SELFDNS';

export const getIsSelfConsumption = (value) =>
    ['SELFCON', 'SELFVB', 'SELFDNS'].includes(value);

export const getIsCompatibleCompensationScheme = ({
    certified,
    name,
    paymentType,
    scheme,
}) =>
    (certified && scheme?.certifiedRates?.includes(name)) ||
    (!certified && scheme?.customRates?.includes(paymentType));

export const getCompatibleCompensationSchemes = ({
    canViewSelfConsumption,
    certified,
    name,
    paymentType,
}) => {
    return COMPENSATION_SCHEMES.map((scheme) => ({
        disabled:
            (!canViewSelfConsumption && getIsSelfConsumption(scheme.value)) ||
            !getIsCompatibleCompensationScheme({
                certified,
                name,
                paymentType,
                scheme,
            }),
        label: i18next.t(scheme.label),
        value: scheme.value,
    }));
};

export const getCompensationSchemeByBackendKey = ({
    backendKey,
    certified,
    name,
    paymentType,
}) => {
    if (!backendKey) return {};

    return COMPENSATION_SCHEMES.find(
        (scheme) =>
            scheme?.backendKey === backendKey &&
            getIsCompatibleCompensationScheme({
                certified,
                name,
                paymentType,
                scheme,
            })
    );
};

export const getCompensationSchemeByValue = (value) => {
    if (!value) return {};
    return COMPENSATION_SCHEMES.find((scheme) => scheme.value === value);
};
