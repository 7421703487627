import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSwitch,
} from 'common/components/form/bootstrap';
import { useBreakpoint } from 'common/hooks';
import { getHasLowTensionConcept } from 'common/utils/helpers/rates';

import FieldArrayConcepts from '../CustomRateFormTabs/SettingsDataTab/FieldArrayConcepts';
import SectionHeader from '../SectionHeader';
import { Label } from '../styledComponents';

const FieldsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    margin-bottom: 43px;
    margin-top: 29px;
`;

const labelColumnSize = { lg: 6, md: 9, xs: 18 };
const inputColumnSize = { lg: 12, md: 9, xs: 18 };

const ChargesTab = ({
    control,
    disabled,
    getValues,
    othersUnitsDataToSelect,
    rateName,
    setValue,
    showMode,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const fullWidth = ['xs', 'sm'].includes(breakpoint);
    const hasLowTensionConcept = getHasLowTensionConcept(rateName);

    return (
        <>
            <Grid container alignItems="center">
                <Grid item {...labelColumnSize}>
                    <Label>{t('Annual percentage increase')}:</Label>
                </Grid>
                <Grid item {...inputColumnSize}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={disabled}
                        fullWidth={fullWidth}
                        max={100}
                        min={0}
                        name="annual_percentage_increase"
                    />
                </Grid>

                <Grid item {...labelColumnSize}>
                    <Label>{t('Right to public lighting')}:</Label>
                </Grid>
                <Grid item {...inputColumnSize}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={disabled}
                        fullWidth={fullWidth}
                        max={100}
                        min={0}
                        name="dap"
                    />
                </Grid>

                <Grid item {...labelColumnSize}>
                    <Label>{t('Tax rate')}:</Label>
                </Grid>
                <Grid item {...inputColumnSize}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={disabled}
                        fullWidth={fullWidth}
                        max={100}
                        min={0}
                        name="tax_rate"
                    />
                </Grid>

                {hasLowTensionConcept && (
                    <>
                        <Grid item {...labelColumnSize} xs={12} sm={15}>
                            <Label>2% {t('Low tension')}:</Label>
                        </Grid>
                        <Grid item {...inputColumnSize} xs={6} sm={3}>
                            <ReactHookFormSwitch
                                control={control}
                                disabled={disabled}
                                name="has_low_tension_concept"
                                sx={{ m: 0 }}
                            />
                        </Grid>
                    </>
                )}
            </Grid>

            <SectionHeader title={t('Other charges')} />
            <FieldsContainer>
                <FieldArrayConcepts
                    control={control}
                    getValues={getValues}
                    isConceptsOtherChargesTaxes
                    name="other_concepts"
                    setValue={setValue}
                    showMode={showMode}
                    unitItems={othersUnitsDataToSelect}
                />
            </FieldsContainer>

            <SectionHeader title={t('Tax', { count: 2 })} />
            <FieldsContainer>
                <FieldArrayConcepts
                    control={control}
                    getValues={getValues}
                    isConceptsOtherChargesTaxes
                    name="taxes_concepts"
                    setValue={setValue}
                    showMode={showMode}
                    unitItems={othersUnitsDataToSelect}
                />
            </FieldsContainer>
        </>
    );
};

ChargesTab.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    getValues: PropTypes.func,
    othersUnitsDataToSelect: PropTypes.array,
    rateName: PropTypes.string,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
};

export default ChargesTab;
