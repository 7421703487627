import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button } from 'sunwise-ui';

import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import { getSessionLocalStorage } from 'common/utils/helpers/session';
import {
    getIsOwnerUser,
    getMainBranchOffice,
} from 'common/utils/helpers/session';

import Form from '../../../../../modules/contactForm/components/Form';
import * as actions from '../../actions';
import ContactSelector from '../common/ContactSelector';

const CustomFooter = ({
    agents,
    handleOnSubmit,
    handleSubmit,
    setIsNewRecord,
}) => {
    const { t } = useTranslation();
    return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, pt: 2 }}>
            <Button onClick={() => setIsNewRecord(false)} sx={{ ml: 'auto' }}>
                {t('Previous')}
            </Button>

            <Button
                onClick={handleSubmit((values) =>
                    handleOnSubmit(values, agents)
                )}
            >
                {t('Next')}
            </Button>
        </Box>
    );
};

CustomFooter.propTypes = {
    agents: PropTypes.array,
    handleSubmit: PropTypes.func,
    handleOnSubmit: PropTypes.func,
    setIsNewRecord: PropTypes.func,
};

const Step1 = ({
    branchOffices,
    onSubmit,
    setStep,
    validateDuplicatedContact,
    values,
}) => {
    const { t } = useTranslation();
    const [isNewRecord, setIsNewRecord] = useState(false);
    const { contact = null } = values;

    const handleOnSubmit = (values) => {
        validateDuplicatedContact(values, () => {
            onSubmit('contact', values);
            setStep(2);
        });
    };

    useEffect(() => {
        if (contact) setIsNewRecord(contact.isNewRecord);
    }, []);

    if (isNewRecord) {
        const isOwner = getIsOwnerUser();
        const session = getSessionLocalStorage();

        const props = contact
            ? { initialValues: contact }
            : {
                  initialValues: {
                      advanced: false,
                      agent: session.id,
                      branch_office: isOwner
                          ? getMainBranchOffice()
                          : branchOffices[0]?.id,
                      company_name: '',
                      company_position: '',
                      contact_origin: null,
                      emails: [],
                      first_lastname: '',
                      id: '',
                      isNewRecord: true,
                      name: '',
                      rfc: '',
                      second_lastname: '',
                      status_contact: '',
                      status_flag: '',
                      telephones: [],
                  },
              };

        return (
            <Form
                {...props}
                canDeleteContacts={false}
                canModify={false}
                CustomFooter={({ agents, handleSubmit }) => (
                    <CustomFooter
                        agents={agents}
                        handleOnSubmit={handleOnSubmit}
                        handleSubmit={handleSubmit}
                        setIsNewRecord={setIsNewRecord}
                    />
                )}
                isNewRecord={true}
            />
        );
    }

    const handleNextStep = () => {
        if (contact.id === 'new-contact') {
            setIsNewRecord(true);
            onSubmit('contact', null);
            onSubmit('tmpContact', null);
            return;
        }
        setStep(2);
    };

    return (
        <>
            <ContactSelector onSubmit={onSubmit} values={values} />

            <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, pt: 2 }}>
                <Button
                    disabled={isEmpty(contact)}
                    onClick={handleNextStep}
                    sx={{ ml: 'auto' }}
                >
                    {t('Next')}
                </Button>
            </Box>
        </>
    );
};

Step1.propTypes = {
    branchOffices: PropTypes.array,
    onSubmit: PropTypes.func,
    setStep: PropTypes.func,
    validateDuplicatedContact: PropTypes.func,
    values: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    branchOffices: multiBranchesSelectors.getFilteredBranchesData,
});

const mapDispatchToProps = (dispatch) => ({
    validateDuplicatedContact: (values, callback) =>
        dispatch(actions.validateDuplicatedContact(values, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
