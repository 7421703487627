import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert, Box, TabPanel, Tabs } from 'sunwise-ui';

import csvImporter from 'common/modules/csvImporter';
import lisaFiles from 'common/modules/lisaFiles';
import yupResolver from 'common/utils/yupResolver';

import InstallationDateWarning from '../../../afterSalesSettings/components/warnings/InstallationDateWarning';
import * as afterSalesSettingsSelectors from '../../../afterSalesSettings/selectors';
import * as supplierIntegrationsSelectors from '../../../supplierIntegrations/selectors';
import * as actions from '../../actions';
import { DEMAND_CONCEPTS, ENERGY_CONCEPTS } from '../../constants';
import { FORM_FIELDS_BY_SECTION } from '../../constants';
import {
    getConsumedBag,
    getHasBagEnergyField,
    getSectionHasErrors,
    handleOnChangeEnergyFields,
    handleOnChangeNetConsumption,
    resetEnergyFields,
    validateEnergyBalance,
} from '../../helpers';
import * as selectors from '../../selectors';
import validate from '../../validate';
import IncompleteCupsWarning from '../IncompleteCupsWarning';

import ContractedDemandFields from './ContractedDemandFields';
import DateFields from './DateFields';
import DemandFields from './DemandFields';
import DemandWithoutSolarFields from './DemandWithoutSolarFields';
import FormBottomActions from './FormBottomActions';
import GeneralInformation from './GeneralInformation';
import NetMeteringFields from './NetMeteringFields';
import PeriodFields from './PeriodFields';
import PPAFields from './PPAFields';
import TabBadge from './TabBadge';
import UploadFile from './UploadFile';

const Form = ({
    availableBag,
    compensationScheme,
    configCsvImporter,
    fetchAvailableBag,
    fetchCupsInfo,
    fetchLisaFiles,
    handleCloseImportModal,
    handleFetchMeasuredEnergy,
    initialValues,
    isFetchingCupsInfo,
    isFetchingMeasuredEnergy,
    isFetchingRateConfiguration,
    isSaving,
    onClose,
    rateConfiguration,
    ratesDictionary,
    resetForm,
    save,
    selectedProject,
    serviceNumber,
    sipsIntegration,
    uploadCsv,
    uploadFile,
}) => {
    const [selectedTab, setSelectedTab] = useState('general');
    const [energyBalanceErrors, setEnergyBalanceErrors] = useState({});

    const {
        control,
        formState: { errors, isSubmitted, isValid },
        handleSubmit,
        getValues,
        reset,
        setValue,
        watch,
    } = useForm({
        context: { ratesDictionary },
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    const { t } = useTranslation();

    const [finalDate, incomplete, initialDate, lastDate, rateId] = watch([
        'final_date',
        'incomplete',
        'initial_date',
        'last_date',
        'rate',
    ]);
    const rate = ratesDictionary[rateId];
    const canUseCups = Boolean(sipsIntegration?.id);
    const disabled =
        isFetchingCupsInfo ||
        isFetchingMeasuredEnergy ||
        isFetchingRateConfiguration ||
        isSaving;

    useEffect(() => {
        return () => resetForm();
    }, []);

    useEffect(() => {
        const newValues = resetEnergyFields({
            formValues: initialValues,
            rateConfiguration,
            rateId,
            ratesDictionary,
            reset,
        });
        setEnergyBalanceErrors(validateEnergyBalance(newValues));
    }, [initialValues]);

    useEffect(() => {
        const newValues = resetEnergyFields({
            formValues: getValues(),
            rateConfiguration,
            rateId,
            ratesDictionary,
            reset,
        });
        setEnergyBalanceErrors(validateEnergyBalance(newValues));
    }, [rateConfiguration, ratesDictionary]);

    const handleOnClose = () => onClose();

    const handleOnClickSubmit = (values) => {
        save({
            callback: handleOnClose,
            project: selectedProject?.id,
            rate,
            values,
        });
    };

    const handleFileUpload = (files) => {
        uploadFile({
            files,
            getValues,
            project: selectedProject,
            setEnergyBalanceErrors,
            setValue,
        });
    };

    const handleChangeAvailableBag = (value) => {
        if (!value) return;
        const consumedBag = getConsumedBag(getValues('total'), value);
        setValue('applied_bag', consumedBag);
    };

    const onChangeDate = () => {
        const formValues = getValues();
        resetEnergyFields({
            formValues,
            rateConfiguration,
            rateId,
            ratesDictionary,
            reset,
        });

        handleFetchMeasuredEnergy({
            finalDate: formValues?.final_date,
            getValues,
            initialDate: formValues?.initial_date,
            projectId: selectedProject?.id,
            rate,
            setEnergyBalanceErrors,
            setValue,
        });

        if (getHasBagEnergyField(rate, compensationScheme))
            fetchAvailableBag(
                selectedProject?.id,
                formValues?.final_date,
                handleChangeAvailableBag
            );
    };

    const handleOnChangeDate = () => {
        if (canUseCups)
            fetchCupsInfo({
                cups: serviceNumber,
                getValues,
                onFailed: onChangeDate,
                projectId: selectedProject?.id,
                rateConfiguration,
                setEnergyBalanceErrors,
                setValue,
            });
        else onChangeDate();
    };

    const handleOnChangePeriodField = (isNetMetField, key) => {
        const func = isNetMetField
            ? handleOnChangeNetConsumption
            : handleOnChangeEnergyFields;
        func({ getValues, key, setEnergyBalanceErrors, setValue });

        const hasBagEnergyField = getHasBagEnergyField(
            rate,
            compensationScheme
        );

        if (hasBagEnergyField) handleChangeAvailableBag(availableBag);
    };

    const handleOnChangeTab = (newValue) => setSelectedTab(newValue);

    return (
        <>
            <form onSubmit={handleSubmit(handleOnClickSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        onChange={(_, newTab) => handleOnChangeTab(newTab)}
                        value={selectedTab}
                        variant="scrollable"
                    >
                        <TabBadge
                            hasErrors={getSectionHasErrors(
                                errors,
                                FORM_FIELDS_BY_SECTION.GENERAL
                            )}
                            label={t('General')}
                            value="general"
                        />

                        <TabBadge
                            hasErrors={energyBalanceErrors?.hasErrors}
                            label={t('Energy')}
                            value="energy"
                            disabled={!initialDate || !finalDate}
                        />

                        <TabBadge
                            hasErrors={getSectionHasErrors(
                                errors,
                                FORM_FIELDS_BY_SECTION.PAYMENT
                            )}
                            label={t('Billing')}
                            value="payment"
                            disabled={!initialDate || !finalDate}
                        />
                    </Tabs>
                </Box>

                <TabPanel
                    key="tab-panel-general"
                    selectedTab={selectedTab}
                    value="general"
                >
                    <UploadFile
                        control={control}
                        disabled={disabled}
                        name="file"
                        onChange={handleFileUpload}
                    />

                    {!disabled && configCsvImporter?.isOpen && (
                        <csvImporter.Container
                            buttonText={t('Upload CSV')}
                            columns={[
                                {
                                    columnName: 'date',
                                    displayLabel: t('Date'),
                                    matchAliases: ['fecha', 'timestamp'],
                                    required: true,
                                    shouldFormat: false,
                                    type: csvImporter.COLUMN_TYPES.DATE,
                                },
                                {
                                    columnName: 'time',
                                    displayLabel: t('Time'),
                                    matchAliases: ['hora', 'time'],
                                    shouldFormat: false,
                                    type: csvImporter.COLUMN_TYPES.TIME,
                                    visible: (_, columnsFormat) =>
                                        columnsFormat?.date &&
                                        !columnsFormat.date.includes('HH'),
                                },
                                {
                                    columnName: ENERGY_CONCEPTS.GENERATION,
                                    displayLabel:
                                        t('Total generation') + ' (kWh)',
                                    matchAliases: [
                                        'generacion',
                                        'generation',
                                        'solar',
                                    ],
                                    type: csvImporter.COLUMN_TYPES.NUMBER,
                                },
                                {
                                    columnName: ENERGY_CONCEPTS.EXPORTED_ENERGY,
                                    displayLabel:
                                        t('Exported solar generation') +
                                        ' (kWh)',
                                    matchAliases: [
                                        'exported',
                                        'exportación',
                                        'exportacion',
                                        'exported energy',
                                        'exported generation',
                                        'exported solar generation',
                                    ],
                                    type: csvImporter.COLUMN_TYPES.NUMBER,
                                },
                                {
                                    columnName:
                                        ENERGY_CONCEPTS.SELF_CONSUMPTION,
                                    displayLabel:
                                        t('Self-consumption') + ' (kWh)',
                                    matchAliases: [
                                        'autoconsumo',
                                        'self_consumed',
                                        'self_consumed_energy',
                                        'self_consumption',
                                        'selfconsumed',
                                        'selfconsumption',
                                    ],
                                    type: csvImporter.COLUMN_TYPES.NUMBER,
                                },
                                {
                                    columnName:
                                        ENERGY_CONCEPTS.GRID_CONSUMPTION,
                                    displayLabel:
                                        t('Consumption from Grid') + ' (kWh)',
                                    matchAliases: [
                                        'consumo de red',
                                        'consumo red',
                                        'grid consumption',
                                        'grid',
                                        'red',
                                    ],
                                    type: csvImporter.COLUMN_TYPES.NUMBER,
                                },
                                {
                                    columnName: ENERGY_CONCEPTS.CONSUMPTION,
                                    displayLabel:
                                        t('Total consumption') + ' (kWh)',
                                    matchAliases: [
                                        'consumo total',
                                        'consumo',
                                        'consumption',
                                        'energy',
                                        'kwh',
                                    ],
                                    type: csvImporter.COLUMN_TYPES.NUMBER,
                                },
                                {
                                    columnName: DEMAND_CONCEPTS.MEASURED,
                                    displayLabel: t('Demand') + ' (kW)',
                                    matchAliases: [
                                        'demand',
                                        'demanda',
                                        'kw',
                                        'power',
                                    ],
                                    type: csvImporter.COLUMN_TYPES.NUMBER,
                                },
                            ]}
                            initialFile={configCsvImporter?.file}
                            onCancel={handleCloseImportModal}
                            onImport={(result, data) =>
                                uploadCsv({
                                    data,
                                    getValues,
                                    rate,
                                    result,
                                    setEnergyBalanceErrors,
                                    setValue,
                                })
                            }
                            returnFormattedData
                            visible={false}
                        />
                    )}

                    <DateFields
                        control={control}
                        disabled={disabled}
                        handleOnChangeDate={handleOnChangeDate}
                    />

                    <InstallationDateWarning date={initialDate} />

                    {incomplete && (
                        <IncompleteCupsWarning lastDate={lastDate} />
                    )}

                    <GeneralInformation
                        control={control}
                        disabled={disabled}
                        ratesDictionary={ratesDictionary}
                        selectedRate={rate}
                        setValue={setValue}
                    />

                    <ContractedDemandFields
                        control={control}
                        disabled={disabled}
                        errors={errors}
                        rate={rate}
                    />
                </TabPanel>

                <TabPanel
                    key="tab-panel-energy"
                    selectedTab={selectedTab}
                    value="energy"
                >
                    {initialDate && finalDate && (
                        <PeriodFields
                            compensationScheme={compensationScheme}
                            control={control}
                            disabled={disabled}
                            energyBalanceErrors={energyBalanceErrors}
                            handleOnChangePeriodField={
                                handleOnChangePeriodField
                            }
                        />
                    )}

                    {energyBalanceErrors?.hasErrors && (
                        <Alert severity="error">
                            {t(
                                'There are errors in the energy balance. Please review it'
                            )}
                        </Alert>
                    )}
                </TabPanel>

                <TabPanel
                    key="tab-panel-payment"
                    selectedTab={selectedTab}
                    value="payment"
                >
                    <DemandFields
                        control={control}
                        disabled={disabled}
                        errors={errors}
                    />

                    <DemandWithoutSolarFields
                        control={control}
                        disabled={disabled}
                        errors={errors}
                        rate={rate}
                    />

                    <NetMeteringFields
                        compensationScheme={compensationScheme}
                        control={control}
                        disabled={disabled}
                        errors={errors}
                        rate={rate}
                    />

                    <PPAFields
                        control={control}
                        disabled={disabled}
                        errors={errors}
                        getValues={getValues}
                        initialDate={initialDate}
                        setValue={setValue}
                    />

                    {((isSubmitted && !isValid) ||
                        energyBalanceErrors?.hasErrors) && (
                        <Alert severity="error">
                            {t(
                                'Complete all fields with the correct information'
                            )}
                        </Alert>
                    )}
                </TabPanel>

                <FormBottomActions
                    disabled={disabled || !finalDate || !initialDate}
                    handleOnChangeTab={handleOnChangeTab}
                    handleOnClose={handleOnClose}
                    hasEnergyBalanceErrors={energyBalanceErrors?.hasErrors}
                    selectedTab={selectedTab}
                    tabs={['general', 'energy', 'payment']}
                />
            </form>

            <lisaFiles.Container
                onCompleteUpload={() =>
                    fetchLisaFiles({
                        getValues,
                        project: selectedProject,
                        rate,
                        rateConfiguration,
                        setEnergyBalanceErrors,
                        setValue,
                    })
                }
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    availableBag: selectors.getAvailableBag,
    compensationScheme:
        afterSalesSettingsSelectors.getAfterSalesSettingsCompensationScheme,
    configCsvImporter: selectors.getConfigCsvImportModal,
    initialValues: selectors.getInitialValues,
    isFetchingCupsInfo: selectors.getIsFetchingCupsInfo,
    isFetchingMeasuredEnergy: selectors.getIsFetchingMeasuredEnergy,
    isFetchingRateConfiguration:
        afterSalesSettingsSelectors.getIsFetchingScheduleRateConfiguration,
    isSaving: selectors.getIsSavingData,
    rateConfiguration: afterSalesSettingsSelectors.getScheduleRateConfiguration,
    ratesDictionary: afterSalesSettingsSelectors.getRatesDictionary,
    serviceNumber: afterSalesSettingsSelectors.getAfterSalesServiceNumber,
    sipsIntegration: supplierIntegrationsSelectors.getSipsIntegration,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAvailableBag: (projectId, date, callback) =>
        dispatch(actions.fetchAvailableBag(projectId, date, callback)),
    fetchCupsInfo: (params) => dispatch(actions.fetchCupsInfo(params)),
    fetchLisaFiles: (params) => dispatch(actions.fetchLisaFiles(params)),
    handleFetchMeasuredEnergy: (params) =>
        dispatch(actions.handleFetchMeasuredEnergy(params)),
    resetForm: () => dispatch(actions.resetForm()),
    save: (params) => dispatch(actions.save(params)),
    uploadCsv: (params) => dispatch(actions.uploadCsv(params)),
    uploadFile: (params) => dispatch(actions.uploadFile(params)),
});

Form.propTypes = {
    availableBag: PropTypes.number,
    compensationScheme: PropTypes.string,
    configCsvImporter: PropTypes.object,
    fetchAvailableBag: PropTypes.func,
    fetchCupsInfo: PropTypes.func,
    fetchLisaFiles: PropTypes.func,
    handleCloseImportModal: PropTypes.func,
    handleFetchMeasuredEnergy: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingCupsInfo: PropTypes.bool,
    isFetchingMeasuredEnergy: PropTypes.bool,
    isFetchingRateConfiguration: PropTypes.bool,
    isSaving: PropTypes.bool,
    onClose: PropTypes.func,
    rateConfiguration: PropTypes.object,
    ratesDictionary: PropTypes.object,
    resetForm: PropTypes.func,
    save: PropTypes.func,
    selectedProject: PropTypes.object,
    serviceNumber: PropTypes.string,
    sipsIntegration: PropTypes.object,
    uploadCsv: PropTypes.func,
    uploadFile: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
